import {
  Icon,
  media,
  RoundButton,
  text,
  useBreakpoint,
  useI18n,
  color,
} from "@sencrop/ui-components";
import { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import CardHeader from "../../../components/CardHeader";
import { Device } from "../../../models/device";
import { DeviceModel, getDeviceModelIcon } from "../../../models/device-model";

const DISPLAY_MODELS = Object.values(DeviceModel);

const TRANSLATIONS_KEYS: Record<DeviceModel, string> = {
  [DeviceModel.RAINCROP]: "stats_card.raincrop",
  [DeviceModel.WINDCROP]: "stats_card.windcrop",
  [DeviceModel.LEAFCROP]: "stats_card.leafcrop",
  [DeviceModel.SOLARCROP]: "stats_card.solarcrop",
  [DeviceModel.DWD]: "stats_card.dwd",
  [DeviceModel.THERMOCROP]: "stats_card.thermocrop",
  [DeviceModel.SOILCROP]: "stats_card.soilcrop",
  [DeviceModel.DAVIS]: "stats_card.davis",
  [DeviceModel.OTHER]: "stats_card.other",
};

type ModelStatistic = {
  model: DeviceModel;
  deviceCount: number;
};

type StatsCardProps = {
  devices: Device[];
  visibleModels: DeviceModel[];
  onToggleModelClick: (model: DeviceModel) => void;
};
const StatsCard = (props: StatsCardProps) => {
  const { devices, onToggleModelClick, visibleModels } = props;

  const devicesCount = devices.length;

  const modelStatistics = useMemo(
    () =>
      DISPLAY_MODELS.map<ModelStatistic>((model) => ({
        model,
        deviceCount: devices.filter((d) => d.model === model).length,
      })).filter((s) => s.deviceCount > 0),
    [devices]
  );

  const { t } = useI18n();

  const onMobile = useBreakpoint("mobile");

  const [isMinimized, setMinimized] = useState<boolean>(onMobile);

  return isMinimized ? (
    <ButtonContainer>
      <RoundButton variant="soft" onClick={() => setMinimized(false)} size="l">
        <Icon name="sencrop" />
      </RoundButton>
    </ButtonContainer>
  ) : (
    <CardContainer>
      <CardHeader
        title={t("stats_card.total", {
          smart_count: devicesCount,
        })}
        onClose={() => setMinimized(true)}
        closeIcon="arrow-dropdown"
      />
      <CardContent>
        {modelStatistics.map((stats) => {
          const isVisible = visibleModels.includes(stats.model);
          return (
            <Item
              key={stats.model}
              $disabled={!isVisible}
              onClick={() => onToggleModelClick(stats.model)}
            >
              <Icon
                name={getDeviceModelIcon(stats.model)}
                style={{ marginRight: "0.5rem" }}
              />
              {t(TRANSLATIONS_KEYS[stats.model])}:
              <span>{stats.deviceCount}</span>
              <Spacer />
              <Icon name={isVisible ? "visibility-o" : "visibility-off-o"} />
            </Item>
          );
        })}
      </CardContent>
    </CardContainer>
  );
};

export default StatsCard;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  width: 100%;
  button {
    pointer-events: auto;
  }
`;

const CardContainer = styled(Card)`
  pointer-events: auto;
  width: 100%;
  ${media.greaterThan("tablet")`
    max-width: 250px;
    margin: 0 0 0 auto;
  `};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

type ItemProps = {
  $disabled?: boolean;
};
const Item = styled.label<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${text("text", "m")};
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  > i {
    margin-right: 0.5rem;
  }
  > span {
    font-weight: 600;
    margin-left: 0.45rem;
  }
  ${(props) =>
    props.$disabled &&
    css`
      color: ${color("text-tertiary")};
    `}
`;
